<template>
  <div class="redirect-page">
    <div class="redirect-content">
      <h2>即将跳转到第三方网站</h2>
      <p class="intro">以下为爬虫自动收录的来源网址信息，是否继续</p>
      <div class="link-wrap">
        <i class="el-icon-link"></i>
        <span v-if="loading" class="link-content">资源链接获取中...</span>
        <span v-else class="link-content">{{
          contentNotEmpty ? link : "该资源内容已失效..."
        }}</span>
      </div>
      <div class="check-wrap" v-if="contentNotEmpty">
        <p class="checking-tip" v-if="isCheckingUrlValid">
          正在检查该链接是否有效...
        </p>
        <p class="result-tip" v-if="!isCheckingUrlValid">
          <span class="valid" v-if="valid == 1">该链接有效</span>
          <span class="invalid" v-if="valid == -1">该链接已经失效</span>
        </p>
      </div>
      <div class="button-wrap">
        <a v-if="loading" class="button disable">链接获取中</a>
        <a v-else-if="contentNotEmpty" class="button" :href="link">继续前往</a>
        <a v-else class="button disable">资源已失效</a>
      </div>
      <p class="tip" v-if="!loading && contentNotEmpty">
        如无法打开跳转链接，请用备用站点：<a
          href="http://www.enfi.vip"
          target="_blank"
          rel="noopener noreferrer"
          >www.enfi.vip</a
        >
      </p>
    </div>
  </div>
</template>
<script>
import { checkUrlFromBaidu, getDetail } from "../api/search";

const formatUrl = (url) => {
  if (!url) {
    return "";
  }
  const index = url.lastIndexOf("http");
  if (index > 0) {
    return url.slice(index);
  } else {
    return url;
  }
};

export default {
  validate({ params }) {
    return params.id;
  },
  data: () => ({
    id: "",
    pwd: "",
    url: "",
    type: "",
    parent: "",
    valid: 0,
    detail: null,
    loading: true,
    isCheckingUrlValid: true,
  }),
  computed: {
    contentNotEmpty() {
      return this.detail && this.detail.filename;
    },
    link() {
      const numDict = {
        "0": 0,
        "1": 1,
        "2": 2,
        "3": 3,
        "4": 4,
        "5": 5,
        "6": 6,
        "7": 7,
        "8": 8,
        "9": 9,
        A: 10,
        B: 11,
        C: 12,
        D: 13,
        E: 14,
        F: 15,
        G: 16,
        H: 17,
        I: 18,
        J: 19,
        K: 20,
        L: 21,
        M: 22,
        N: 23,
        O: 24,
        P: 25,
        Q: 26,
        R: 27,
        S: 28,
        T: 29,
        U: 30,
        V: 31,
        W: 32,
        X: 33,
        Y: 34,
        Z: 35,
      };
      const id = this.$route.params.id;
      if (!id) {
        return "";
      }
      const lastChar = id[id.length - 1];
      const pageIndex = (numDict[lastChar.toUpperCase()] % 4) + 1;
      let targetHost = this.bbsSites[pageIndex - 1];
      if (localStorage.getItem(targetHost) === "false") {
        const results = this.bbsSites.filter(
          (item) => localStorage.getItem(item) === "true"
        );
        targetHost = results[(pageIndex - 1) % results.length];
      }

      if (pageIndex === 2) {
        return `${targetHost}/#/main/detail-${pageIndex}/${id}`;
      }
      if (pageIndex === 3) {
        return `${targetHost}/#/main/detail-${pageIndex}/${id}`;
      }
      if (pageIndex === 4) {
        return `${targetHost}/#/main/detail-${pageIndex}/${id}`;
      }
      return `${targetHost}/#/main/detail-${pageIndex}/${id}`;
    },
  },
  async mounted() {
    this.id = this.$route.params.id;
    this.type = this.$route.query.type;
    this.parent = this.$route.query.parent || "";
    if (this.id) {
      await this.getResourceDetail();
      if (this.valid == 0) this.checkUrlValid(this.id);
    }
  },
  methods: {
    async checkUrlValid(id) {
      this.isCheckingUrlValid = true;
      const res = await checkUrlFromBaidu(id);
      if (res) {
        this.valid = res[id];
      }
      this.isCheckingUrlValid = false;
    },

    async getResourceDetail() {
      this.loading = true;
      if (this.id) {
        this.detail = await getDetail({
          id: this.id,
          type: this.type,
          parent: this.parent,
          size: 15,
        });
        if (this.detail && this.detail.filename) {
          this.pwd = this.detail.pwd;
        }
        if (this.detail && this.detail.url) {
          this.url = formatUrl(this.detail.url);
          if (
            this.type === "aliyundrive" &&
            this.parent &&
            this.parent != "undefined"
          ) {
            this.url = `${this.url}/folder/${this.parent}`;
          }
        }
      }
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_var.scss";
.redirect-page {
  min-height: calc(100vh - 200px);
  padding: 20px;
  .redirect-content {
    text-align: center;
    max-width: 100%;
    width: 600px;
    padding: 40px;
    border-radius: 10px;
    background-color: #fff;
    margin: 20px auto;
    .intro {
      font-size: 14px;
      color: #666;
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }
  .link-wrap {
    display: flex;
    align-items: center;
    width: 460px;
    max-width: 100%;
    margin: 12px auto 0;
    padding: 10px;
    border-radius: 4px;
    background: #fafafa;
    border: 1px solid #dddddd;
    i {
      font-size: 30px;
      color: #666;
    }
    .link-content {
      font-size: 14px;
      color: #3194d0;
      margin-left: 10px;
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .tip {
    font-size: 12px;
    margin-top: 40px;
    color: #999;
  }
  .check-wrap {
    margin-top: 15px;
    font-size: 13px;
    text-align: center;
    font-weight: bold;
    .checking-tip {
      color: #666;
    }
    .result-tip {
      .valid {
        color: #41b035;
      }
      .invalid {
        color: red;
      }
    }
  }
  .button-wrap {
    margin-top: 20px;
    .button {
      display: inline-block;
      width: 144px;
      height: 44px;
      line-height: 43px;
      border-radius: 22px;
      font-size: 14px;
      color: $theme-color;
      border: 1px solid $theme-color;
      cursor: pointer;
      text-decoration: none;
      &.disable {
        color: #999;
        border-color: #999;
      }
    }
  }
}
</style>
